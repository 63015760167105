import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { H6 } from '@system'

const useStyles = makeStyles((theme) => ({
  labelledLinkRoot: {
    color: theme.palette.common.white,
    '& a': {
      fontSize: '1rem',
      color: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    '& a:hover': {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    marginBottom: '1rem',
  },
  label: {
    fontSize: '1rem',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const LabelledLink = ({ blok }) => {
  const { label, link } = blok
  const classes = useStyles()

  return (
    <SbEditable content={blok}>
      <Box className={classes.labelledLinkRoot}>
        {label && <H6 className={classes.label}>{label}</H6>}
        {renderBloks(link)}
      </Box>
    </SbEditable>
  )
}

export default LabelledLink
